import React, { Component } from 'react'
import {Link} from 'gatsby'
import abstractMenu from './abstractMenu'

//Can't dynamically change staticquery, so just making a basic
//component here for TULSA and then another for other menu items
class PetctMenu extends Component {

  render(){

    if(!this.props.mobile){
      return (
        <li className="tulsa-menu" onMouseOver={() => {
            this.props.setMenu({menu: true}, ()=> {
                document.addEventListener('click', this.props.hideDropdownMenu)
            })
        }} onMouseOut={() => {
            this.props.setMenu({menu: false})
        }}><Link to="/pet-ct/psma/">PET/CT</Link>
          <ul className={`tulsa-inner-menu ${this.props.menu ? 'show': 'hide'}`} onMouseOut={() => {
              this.props.setMenu({menu: false})
          }}>
            <li><Link className="button" to="/pet-ct/psma/">PSMA</Link></li>
            <li><Link className="button" to="/pet-ct/axumin/">Axumin</Link></li>
          </ul>
        </li>
      )
    }else {
      return (

        <li><a href='#' onClick={e => {
            e.preventDefault()
            this.props.setMenu({menu: true}, ()=> {
                document.addEventListener('click', this.props.hideDropdownMenu)
            })

        }}>PET/CT</a>
          <ul className={`${this.props.menu ? 'show': 'hide'}`} >
            <li><Link className="button" to="/pet-ct/psma/">PSMA</Link></li>
            <li><Link className="button" to="/pet-ct/axumin/">Axumin</Link></li>
          </ul>
        </li>
      )
    }
  }
}

export default abstractMenu(PetctMenu)
