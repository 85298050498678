import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import favicon from "../images/favicon.ico"

export default ({page='', desc=''}) => {
    const data = useStaticQuery(graphql`
    query {
        site {
            siteMetadata {
                title,
                siteUrl,
                description,
                keywords
            }
        }
    }
    `)
   
    let capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <meta name="description" content={`${desc !== '' ? desc : data.site.siteMetadata.description}`}/>
            <meta name="keywords" content={data.site.siteMetadata.keywords} />
            <title>{ `${capitalize(page !== '' ? page : "@")} | ${data.site.siteMetadata.title}`}</title>
            <link href='https://api.mapbox.com/mapbox-gl-js/v1.8.1/mapbox-gl.css' rel='stylesheet' />
            <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
            <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
            <link rel="icon" href={favicon} />
            <script type="text/javascript">
                {`!function(){var b=function(){window.__AudioEyeSiteHash = "eb00ab13fdd0175f5c4d6187de3ec6f0"; var a=document.createElement("script");a.src="https://wsmcdn.audioeye.com/aem.js";a.type="text/javascript";a.setAttribute("async","");document.getElementsByTagName("body")[0].appendChild(a)};"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",b):window.attachEvent&&window.attachEvent("onload",b):b()}();`}
            </script>
            <script defer src="https://connect.podium.com/widget.js#API_TOKEN=682b8c5c-72e7-4590-a6f0-1a23523a4698" id="podium-widget" data-api-token="682b8c5c-72e7-4590-a6f0-1a23523a4698"></script>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-Q4MQRHKMBG"></script>
            <script>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-Q4MQRHKMBG');`}
            </script>
        </Helmet>
    )
}
