import React from "react"
import { Link } from "gatsby"
import CookieConsent from "../components/CookieConsent";
import logo from "../images/footer-logo.png"
import fbIcon from "../images/radnet_facebook.svg"
import twIcon from "../images/radnet_twitter.svg"
import instaIcon from "../images/radnet_instagram.svg"
import ytIcon from "../images/radnet_youtube.svg"
import pinIcon from "../images/radnet_pinterest.svg"
import ttIcon from "../images/radnet_tiktok.svg"
import liIcon from "../images/radnet_linkedin.svg"



export default () => {

    var year = new Date().getFullYear();
  return (
      <footer className="cf">
          <div className="container-outer-xs container-outer-m container-outer">
              <div className="fl-l w-100-l">
                  <div className="tc tl-l footer-social fl-l w-third-l">
                      <Link to="/"><img className="logo" src={logo} alt="RadNet Prostate" /></Link>
                      <ul className="pt3 pt2-l">
                        <li><a href="https://www.instagram.com/radnetimaging" target="_blank"> <img src={instaIcon} width="40" alt="RadNet Instagram" /> </a> </li>
                        <li><a href="https://www.facebook.com/radnetimaging" target="_blank"> <img src={fbIcon} width="40" alt="RadNet Facebook" /> </a> </li>
                        <li><a href="https://www.twitter.com/radnetimaging" target="_blank"> <img src={twIcon} width="40" alt="RadNet Twitter" /> </a> </li>
                        <li><a href="https://www.youtube.com/radnetvideos" target="_blank"> <img src={ytIcon} width="40" alt="RadNet Youtube Videos" /> </a> </li>
                        <li><a href="https://www.pinterest.com/RadNetImaging" target="_blank"> <img src={pinIcon} width="40" alt="RadNet Pinterest" /> </a> </li>
                        <li><a href="https://www.tiktok.com/@radnetimaging" target="_blank"> <img src={ttIcon} width="40" alt="RadNet TikTok" /> </a> </li>
                        <li><a href="https://www.linkedin.com/company/radnet" target="_blank"> <img src={liIcon} width="40" alt="RadNet LinkedIn" /> </a> </li>
                      </ul>
                      <p>RadNet has been a national leader in providing high quality, cost-effective diagnostic imaging services for over 35 years. We are dedicated to advancing early detection of disease and to empowering patients to take charge of their health.</p>
                  </div>
                  <div className="cf">
                      <div className="fl-l w-third-l">
                          <h5>Contact & Information</h5>
                          <ul>
                              <li><a href="https://www.radnet.com/privacy-statement/" rel="noreferrer" target="_blank">Policies & Compliance</a></li>
                              <li><a href="http://radnet.com/disclaimer/" rel="noreferrer" target="_blank">Disclaimer</a></li>
                              <li><a href="http://radnet.com/hipaa-notification-ca/" rel="noreferrer" target="_blank">HIPAA Notification</a></li>
                              <li><a href="http://radnet.com/anti-discrimination-policy" rel="noreferrer" target="_blank">Anti-Discrimination Policy</a></li>
                              <li><a href="https://www.radnet.com/accessibility-statement" rel="noreferrer" target="_blank">Accessibility Statement</a></li>
                              <li><a href="https://www.radnet.com/consumer-rights-ca" rel="noreferrer" target="_blank">Your Privacy Choices</a></li>
                              <li><a href="javascript:void(0);" data-cc="c-settings" class="cc-link" aria-haspopup="dialog">Privacy Settings</a></li>
                          </ul>
                      </div>
                      <div className="fl-l w-third-l cf our-locations">
                          <h5>Our Locations</h5>
                          <div className="fl-l w-third-l">
                              <ul>
                                  <li><Link to="/locations/kern-radiology-downtown-advanced/">Bakersfield</Link></li>
                                  <li><Link to="/locations/beverly-tower-wilshire-advanced-imaging/">Beverly Hills</Link></li>
                                  <li><Link to="/locations/sierra-medical-imaging/">Clovis</Link></li>
                                  <li><Link to="/locations/corona-comprehensive-imaging-center/">Corona</Link></li>
                                  <li><Link to="/locations/liberty-pacific-advanced-imaging-encino/">Encino</Link></li>
                                  <li><Link to="/locations/memorial-care-imaging-center-long-beach/">Long Beach</Link></li>
                                  <li><Link to="/locations/rolling-oaks-radiology-oxnard/">Oxnard</Link></li>
                              </ul>
                          </div>
                          <div className="fl-l w-third-l">
                              <ul>


                                  <li><Link to="/locations/desert-advanced-imaging-palm-springs/">Palm Springs</Link></li>
                                  <li><Link to="/locations/imaging-specialists-pasadena/">Pasadena</Link></li>
                                  <li><Link to="/locations/redlands-advanced-imaging/">Redlands</Link></li>
                                  <li><Link to="/locations/healthcare-advanced-imaging/">Riverside</Link></li>
                                  <li><Link to="/locations/west-coast-radiology-santa-ana/">Santa Ana</Link></li>
                                  <li><Link to="/locations/resolution-imaging/">Santa Monica</Link></li>
                                  <li><Link to="/locations/liberty-pacific-advanced-imaging-tarzana/">Tarzana</Link></li>
                              </ul>
                          </div>
                          <div className="fl-l w-third-l">
                              <ul>

                                  <li><Link to="/locations/temecula-valley-advanced-imaging/">Temecula</Link></li>
                                  <li><Link to="/locations/rolling-oaks-radiology-thousand-oaks/">Thousand Oaks</Link></li>
                                  <li><Link to="/locations/elite-advanced-imaging-center/">Victorville</Link></li>
                                  <li><Link to="/locations/norcal-imaging-walnut-creek/">Walnut Creek</Link></li>
                                  <li><Link to="/locations/westchester-advanced-imaging/">Westchester</Link></li>
                                  <li><Link to="/locations/liberty-pacific-advanced-imaging-west-hills/">West Hills</Link></li>
                              </ul>
                          </div>
                      </div>
                  </div>
               </div>
          </div>
          <CookieConsent/>
      </footer>
  )

}
