import React, { useState, useEffect } from "react"
import {Link} from "gatsby"
import {useStaticQuery, graphql} from "gatsby"
import { update } from "lodash-es"

function HearFromYouForm(props){
    const [idToken, setIdToken] = useState('');
    
    useEffect(() => {
        const fetchIdToken = async () => {
          const response = await fetch('https://prostate.radnetimaging.com/mri-form/', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              action: 'get-token',
            }),
          });
    
          const data = await response.json();
          setIdToken(data.idToken);
        };
    
        fetchIdToken();
    }, []);

    let locationData = useStaticQuery(
        graphql`
            query {
                allNodeCenterLocation {
                    edges {
                        node {
                            title
                            field_location_procedures
                        }
                    }
                }
            }
        `
    )

    let locations = locationData.allNodeCenterLocation.edges

    let tokenRef = React.createRef()
    let nameRef = React.createRef()
    let emailRef = React.createRef()
    let centerRef = React.createRef()
    let phoneRef = React.createRef()
    let [service, setService] = useState('prostate-mri')
    let messageRef = React.createRef()
    let [submitted, setSubmitted] = useState(false)


    function validate(e){
        e.preventDefault()

        let errors = []
        if(nameRef.current.value.length <= 2) errors.push("Fix Name");
        if(emailRef.current.value === '') errors.push("Fix Email");
        if(phoneRef.current.value === '') errors.push("Fix Email");
        if(centerRef.current.value === 'select') errors.push("Select a center");
        if(messageRef.current.value.length <= 2 ) errors.push("Please write a message");

        //if no errors
        if(errors.length == 0){
            handleSubmit()
        }else {
          let out = ''
          errors.forEach((error)=> out += error + "\n" )
          alert(`Please make sure all fields are filled out correctly:\n${out}`)
        }
    }

    function handleSubmit(){
      //All good? Submit!!
        setSubmitted(true)

        let dataToSend = {
            token: tokenRef.current.value,
            name: nameRef.current.value,
            email: emailRef.current.value,
            phone: phoneRef.current.value,
            service: 'tulsa-pro',
            source: 'tulsa-pro',
            action: 'send-form',
            center: centerRef.current.value,
            message: messageRef.current.value
        }

        console.log(dataToSend);

         fetch('https://prostate.radnetimaging.com/mri-form/',{
             headers: {
                 'Accept': 'application/json',
                 'Content-Type': 'application/json'
             },
             method: "POST",
             credentials: 'include',
             body: JSON.stringify(dataToSend)
         }).then(res => res.json())
         .then(res => { //Success
          })
         .catch(err => console.log(err))

    }

    return (
        <div className="hearfromyou tc mt5-l mb5">
            <h2>We Want To Hear From You</h2>
            <div className="flex flex-wrap justify-center">
                <div className="w-100 w-50-l">
                    <h2>Prostate MRI</h2>
                    <p>Call and we'll help schedule your Prostate MRI screening appointment and answer your questions.</p>
                    <br/>
                    <div className="form-inner ">
                        <p>Arizona: <a href="tel:+14804551850" class="b">(480) 455-1850</a></p>
                        <p>Fresno: <a href="tel:+15594744290" class="b">(559) 474-4290</a></p>
                        <p>Coachella Valley: <a href="tel:+17603182988" class="b">(760) 318-2988</a></p>
                        <p>Victor Valley: <a href="tel:+17609512867" class="b">(760) 951-2867</a></p>
                        <p>Inland Empire: <a href="tel:+19516821099" class="b">(951) 682-1099</a></p>
                        <p>Antelope Valley: <a href="tel:+16619455855" class="b">(661) 945-5855</a></p>
                        <p>Long Beach: <a href="tel:+17147841643" class="b">(714) 784-1643</a></p>
                        <p>Los Angeles: <a href="tel:+13108547722" class="b">(310) 854-7722</a></p>
                        <p>San Fernando Valley: <a href="tel:+18186166538" class="b">(818) 616-6538</a></p>
                        <p>San Gabriel Valley: <a href="tel:+16264454850" class="b">(626) 445-4850</a></p>
                        <p>Temecula Valley: <a href="tel:+19515878956" class="b">(951) 587-8956</a></p>
                        <p>Ventura County: <a href="tel:+18053570067" class="b">(805) 357-0067</a></p>
                        <p>Orange County: <a href="tel:+17147841643" class="b">(714) 784-1643</a></p>
                        <p>Bakersfield: <a href="tel:+16613247000" class="b">(661) 324-7000</a></p>
                    </div>
                </div>
                <div className="w-100 w-50-l">
                    <h2>TULSA Procedure</h2>
                    <p>Call or submit the form to start the TULSA Procedure process with our team. We're here to guide you through each step and answer any questions you have.</p>
                    <br/>
                    <p>TULSA Procedure Phone: <a className="b" href="tel:+18184807266">(818) 480-7266</a></p>
                    <p>TULSA Procedure Fax: <a className="b" href="tel:+18188275152">(818) 827-5152</a></p>
                    
                    {/*<p>Orange County Phone: <a className="b" href="tel:+17148613746">(714) 861-3746</a></p>*/}
                    {/*<p>Orange County Fax: <a className="b" href="tel:+17149196877">(714) 919-6877</a></p>*/}
                    
                    <form className={`${submitted ? 'hide': 'show'} mt3 mb4`} onSubmit={validate}>
                        <input type="hidden" name="idToken" id="idToken" value={idToken} ref={tokenRef}/>
                        <div className='form-inner'>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l"><label htmlFor="name" className="item">Your Name: </label></p>
                                <p className="fl-l w-75-l"><input className={``} ref={nameRef} name="name" type="text" required placeholder="First Name, Last Name" /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l"><label htmlFor="email" className="item">Your Email: </label></p>
                            <p className="fl-l w-75-l"><input className={``} ref={emailRef} name="email" type="email" placeholder="example@address.com" required /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l"><label htmlFor="phone" className="item">Your Phone: </label></p>
                            <p className="fl-l w-75-l"><input className={``} ref={phoneRef} name="phone" type="text" placeholder="000-000-0000" required /></p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l"><label htmlFor="location" className="item">Location: </label></p>
                                <p className="fl-l w-75-l">
                                <select name="location" ref={centerRef} defaultValue="select" required>
                                    <option disabled value="select">--Select--</option>
                                    {locations.filter(item => {
                                        let tulsa = false;
                                        let {field_location_procedures} = item.node;
                                        if (field_location_procedures) {
                                            field_location_procedures.forEach(it => {
                                                if (item.node.title === 'Liberty Pacific Advanced Imaging | West Hills' || item.node.title === 'Arizona Diagnostic Radiology Park Central' || item.node.title === 'WaveImaging Saddleback') {
                                                    tulsa = true;
                                                }
                                            });
                                        }
                                        return tulsa;
                                    }).map((item, idx) => {
                                        let {title} = item.node;
                                        return <option key={idx} value={`${title}`}>{title}</option>;
                                    })}
                                </select>
                                </p>
                            </fieldset>
                            <fieldset className="cf">
                                <p className="fl-l w-25-l"><label className="item">Message: </label></p>
                                <p className="fl-l w-75-l"><textarea className={``} ref={messageRef} required name="text" ></textarea></p>
                            </fieldset>
                        </div>
                        <div className="mt4">
                            <button className={`submit-form mr0 mr2-l mb3 mb0-l`} disabled={`${submitted ? 'disabled' : ''}`} type="submit">TULSA Procedure Request</button>
                        </div>
                    </form>
                    <div className={`${submitted ? 'show':'hide'}`}>
                        Message Sent!
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HearFromYouForm